<template>

    <div class='box'>
      <div class='nav' style="margin-top:0px">
        <div class="navbar">
            <template v-for="item in data.navbar" :key="item">
                <text @click="clickNav(item)" :class="'item '+(search.statusCode==item.key ? 'active' : '')">{{item.value}}</text>
            </template>
          
        </div>
    
    
        <!--首页-->
        <div class='atc' style="margin-top:0px;padding-top:3rem;">
            <div style="text-align:center;margin-top:100px;text-align:center;color:#6c6c6c;" v-if="data.list.length<=0">
          <img src="../../assets/img/bx/xiadanchenggong.png" style="margin-top:100px;width:15rem;height:12rem;"/>
          <div>暂无订单</div>
          </div>
          <!-- <div style="height: 3rem;"></div> -->
          <div style="" class='list-box' v-for="item in data.list" :key="item">
            <div class='list' @click="clickOrder(item)">
              <div class='list-a'>
                <text class='list-text'>订单编号：{{item.order_code}}</text>
                <div class='lits-right'>
                  <i1 v-if="item.status_code==0">待领取</i1>
                  <i1 v-else-if="item.status_code==1">已领取</i1>
                  <i1 v-else-if="item.status_code==2">待接单</i1>
                  <i1 v-else-if="item.status_code==3">已接单</i1>
                  <i1 v-else-if="item.status_code==4">已完成</i1>
    
                  <i1 v-else-if="item.status_code==9">已过期</i1>
                  <i1 v-else>进行中</i1>
                  <!-- <img class='img' src='../../img/icon_more_h.png'/> -->
                </div>
              </div>
              <div class='list-op'>
                <div class='text-a'>
                  <div class="icon3">商</div>
                </div>
                <div class='text-b'>
                  <text class='tet2'>{{item.product.product_title}}</text>
                </div>
              </div>
              <div class='list-op' v-if="item.status_code==2||item.status_code==3||item.status_code==4">
                <div class='text-a'>
                  <div class="icon">发</div>
                </div>
                <div class='text-b'>
                  <text class='tet'>{{item.order_name}} {{item.order_phone}}</text>
                  <text class='txt'>{{item.order_address}}</text>
                </div>
              </div>
              <div class='list-op' v-if="item.status_code==3||item.status_code==4">
                <div class='text-a'>
                  <div class="icon2">服</div>
                </div>
                <div class='text-b'>
                  <text class='tet'>{{item.service_name}}</text>
                  <text class='txt'>{{item.service_phone}}</text>
                </div>
              </div>
              <div class='list-ox'>
                <div class='list-oc'>{{item.create_time}}
                  <!-- <div v-if="status_code==0" class='btnshare' data-order_no="orderid" catchtap="sharenow">再次分享</div> -->
                </div>
    
                
                <div class='list-ov'>
                  
                  <div class='list-ov-a'>
                      
    
                    <div class='btn' @click="clickShare(item)" v-if="item.status_code==0">再次分享</div>
                    <div class="btntime" v-if="item.status_code==2||item.status_code==3||item.status_code==4">预约：{{item.desire_time}}</div>
    
                    
                  </div>
                </div>
    
    
    
              </div>
    
    
            </div>
          </div>
        </div>
    
        
      </div>
    </div>
    </template>
    <script setup>
        import {} from 'vant';
        import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
        import Menu from '../../components/Menu.vue'
        import {useRoute,useRouter} from 'vue-router'
        import http from '../../common/bxhttp.js';
        import {isJSON,isNull,showMessage,nullStr,disLabel,getTitle1,getTitle2} from '../../common/utils.js'
        // import KfCard from '../../components/KfCard.vue'
        import ShareCard from '../../components/ShareCard.vue'
    
        let route=useRoute()
        let router=useRouter()
        let menuIndex={index:1}
        
        let data=reactive({
            navbar: [{"key":"","value":"全部"},{"key":"2","value":"待接单"},{"key":"3","value":"已接单"},{"key":"4","value":"已完成"}],
            list:[],
            type:route.query.type
        })
        
        
        let search=reactive({
            statusCode:"",
            searchType:'02',
        })
    
        function clickNav(item){
            search.statusCode=item.key;
            refresh();
        }
    
        function clickOrder(item){
            router.push({ path: '/bxserviceorderadd',query:{"productCode":item.product_code,"orderCode":item.order_code} })
        }
    
        function refresh(){
            http.post("zbx/order/orderservicelist",search).then((res)=>{
              // console.log(JSON.stringify(res))
              if(res.errcode=="0"){
                data.list=res.rows;
              }else{
                showMessage(res.errmsg);
              }
            })
            
        }
    
        refresh();
    
    </script>
    <style scoped lang="scss">
.box {
      background: #eee;
      overflow: hidden;
    }
    
    .bix {
      width: 100%;
      height: 55px;
      background: #eee;
      position: fixed;
    }
    
    .input {
      width: 700rpx;
      height: 28px;
      margin: 10px auto;
      background: white;
      overflow: hidden;
      margin-left: -350rpx;
      left: 50%;
      border-radius: 5px;
      position: fixed;
      top: 0px;
    }
    
    .input-img {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      float: left;
      margin-left: 5px;
    }
    
    .imgss {
      width: 15px;
      height: 15px;
    }
    
    .input-t {
      width: 500rpx;
      height: 27px;
      float: left;
      display: block;
      padding-left: 20rpx;
      font-size: 13px;
      background: #fff;
    }
    
    .input-placeholder {
      font-size: 12px;
    }
    
    .text {
      width: 45px;
      display: block;
      float: right;
      height: 100%;
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(238, 80, 0, 1);
      line-height: 28px;
      text-align: center;
      background: #eee;
    }
    
    .nav {
      width: 100%;
      height: auto;
    }
    
    .navbar {
      width: 100%;
      position: fixed;
      /* top: 48px; */
      flex: none;
      display: flex;
      background: #fff;
      overflow: hidden;
    }
    
    .navbar .item {
      overflow: hidden;
      position: relative;
      flex: auto;
      width: 25%;
      text-align: center;
      line-height: 2.3rem;
      font-size: 1rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(20, 19, 19, 1);
    }
    
    .navbar .item.active {
      color: #ee5000;
      border-bottom: 1px #ee5000 solid;
    }
    
    .atc {
      background: #fff;
      margin-top: 85px;
    }
    
    .list-box {
      width: 710rpx;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
    }
    
    .list {
      width: 95%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 3px 0px rgba(41, 41, 41, 0.2);
      border-radius: 9px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      margin: 8px auto;
    }
    
    .list-a {
      width: 95%;
      height: 48px;
      margin: 0 auto;
    }
    
    .list-text {
      font-size: 0.9rem;
      color: rgba(108, 108, 108, 1);
      line-height: 48px;
    }
    
    .lits-right {
      float: right;
      /* width: 180rpx; */
      height: 2rem;
      text-align: center;
      line-height: 2rem;
      font-size: 0.9rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(238, 19, 50, 1);
    }
    
    .img {
      width: 14px;
      height: 14px;
      float: right;
      margin-top: 17px;
    }
    
    .list-op {
      width: 95%;
      height: 3.2rem;
      margin: 0 auto;
    }
    
    .text-a {
      float: left;
      // width: 24px;
      // height: 24px;
      margin-top: 0.5rem;
    }
    
    .imgs {
      width: 24px;
      height: 24px;
    }
    
    .text-b {
      overflow: hidden;
      width: 240px;
      height: 3rem;
      margin-left: 0.8rem;
      float: left;
    }
    
    .tet {
      width: 100%;
      // height: 20px;
      float: left;
      margin-top: 5px;
      font-size: 1rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(57, 58, 59, 1);
    }
    
    .tet2 {
      width: 100%;
      height: 48px;
      line-height: 43px;
      float: left;
      /* margin-top: 5px; */
      font-size: 1rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(57, 58, 59, 1);
    }
    
    .txt {
      width: 100%;
      // height: 20px;
      float: left;
      font-size: 0.9rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(94, 94, 94, 1);
    }
    
    .list-ox {
      width: 95%;
      height: 40px;
      margin: 0 auto;
    }
    
    .list-oc {
      width: 50%;
      height: 40px;
      font-size: 0.9rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(108, 108, 108, 1);
      line-height: 40px;
      float: left;
    }
    
    .list-ov {
      width: 50%;
      height: 40px;
      float: left;
    }
    
    .list-ov-a {
      width: 100%;
      height: 40px;
      float: left;
    }
    
    .btng {
      width: 60px;
      height: 1rem;
      background: rgb(255, 0, 0);
      box-shadow: 0px 0px 3px 0px rgba(41, 41, 41, 0.2);
      border-radius: 5px;
      font-size: 0.9rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 1rem;
      float: right;
      margin-top: 10px;
      text-align: center;
      margin-right: 0;
    }
    
    .btnshare {
      width: 60px;
      height: 50rpx;
      background: rgb(250, 0, 217);
      box-shadow: 0px 0px 3px 0px rgba(41, 41, 41, 0.2);
      border-radius: 5px;
      font-size: 12px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
      margin-right: 0;
    }
    
    .btn {
      width: 60px;
      height: 50rpx;
      background: #fff;
      box-shadow: 0px 0px 1px 0px rgba(238, 80, 0, 1);
      /* background: rgba(238, 80, 0, 1); */
      /* box-shadow: 0px 0px 3px 0px rgba(41, 41, 41, 0.2); */
      border-radius: 5px;
      font-size: 12px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color:rgba(238, 80, 0, 1);
      /* color: rgba(255, 255, 255, 1); */
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
      margin-right: 0;
    }
    
    .btntime {
      // width: 140px;
      // height: 50rpx;
      background: #fff;
      /* box-shadow: 0px 0px 1px 0px rgba(238, 80, 0, 1); */
      /* background: rgba(238, 80, 0, 1); */
      /* box-shadow: 0px 0px 3px 0px rgba(41, 41, 41, 0.2); */
      border-radius: 5px;
      font-size: 0.9rem;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color:rgba(238, 80, 0, 1);
      /* color: rgba(255, 255, 255, 1); */
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
      margin-right: 0;
    }
    
    .btns {
      width: 60px;
      height: 50rpx;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(65, 151, 250, 1);
      box-shadow: 0px 0px 2px 0px rgba(65, 151, 250, 0.8);
      border-radius: 5px;
      font-size: 12px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: rgba(65, 151, 250, 1);
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
    }
    
    .btnb {
      width: 60px;
      height: 50rpx;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #6c6c6c;
      box-shadow: 0px 0px 2px 0px rgba(65, 151, 250, 0.8);
      border-radius: 5px;
      font-size: 12px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: #6c6c6c;
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
    }
    
    .btna {
      width: 60px;
      height: 50rpx;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #ee5000;
      box-shadow: 0px 0px 2px 0px rgba(65, 151, 250, 0.8);
      border-radius: 5px;
      font-size: 12px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: #ee5000;
      line-height: 50rpx;
      float: right;
      margin-top: 10px;
      text-align: center;
    }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      
      font-size: 1rem;
      font-weight: 400;
      
      line-height: 1.5rem;
      text-align: center;
      border-radius: 50%;
      background: rgba(238, 80, 0, 1);
      color:white;
      /* 
      background: white;
      color: rgba(238, 80, 0, 1);
      border: 1px solid rgba(238, 80, 0, 1); */
      float: left;
    }
    .icon2 {
      width: 1.5rem;
      height: 1.5rem;
      
      font-size: 1rem;
      font-weight: 400;
      
      line-height: 1.5rem;
      text-align: center;
      border-radius: 20px;
      background: rgba(54, 153, 255, 1);
      color:white;
      /* 
      background: white;
      color: rgba(238, 80, 0, 1);
      border: 1px solid rgba(238, 80, 0, 1); */
      float: left;
    }
    
    .icon3 {
      width: 1.5rem;
      height: 1.5rem;
      
      font-size: 1rem;
      font-weight: 400;
      
      line-height: 1.5rem;
      text-align: center;
      border-radius: 20px;
      background: rgb(3, 82, 255);
      color:white;
      /* 
      background: white;
      color: rgba(238, 80, 0, 1);
      border: 1px solid rgba(238, 80, 0, 1); */
      float: left;
    }
    </style>